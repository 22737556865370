<div class="wrapper">
  <section class="section-profile-cover section-shaped my-0">
    <img class="bg-image" src="assets/img/ill/p8.svg" style="width: 100%;"/>
    <div class="separator separator-bottom separator-skew">
      <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <polygon class="fill-secondary" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
  </section>
  <section class="section bg-secondary">
    <div class="container">
      <div class="card card-profile shadow mt--300">
        <div *ngIf="loading" id="border-component" class="tab-pane tab-example-result fade show active" role="tabpanel" aria-labelledby="border-component-tab">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div *ngIf="!loading" class="px-4">
          <div class="col-md-12 row justify-content-center post-start">
            <div class="col-md-12 row justify-content-center post-image-container">
              <div class="card-profile-image">
                <img class="rounded-circle" src="{{ post.data?.featured_image }}" />
              </div>
            </div>
          </div>
          <div class="row justify-content-center mt-o-100">
            <div class="text-center mt-0">
              <h1>{{ post.data?.title }}</h1>
              <div class="h6">
                {{ post.data?.published | date }}
              </div>
            </div>
          </div>
          <div class="mt-5 border-top">
            <div class="row">
              <div class="col-md-12 post-content">
                <div class="col-md-12 post-details" [innerHTML]="post.data?.body"></div>
              </div>
              <!-- <div class="call-to-action">
                <a class="btn btn-lg btn-primary call-to-action-button" href="#">Learn More</a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
