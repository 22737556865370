<div class="page-header">
  <div class="page-header-image" style="background-image: url('assets/img/ill/p8.svg')"></div>
  <div class="container ph-200">
    <div class="row">
      <div class="col-md-6 mx-auto text-center transparent-background">
        <h2 class="display-2 home-title">Fresh Health Network</h2>
      </div>
    </div>
    <div class="row tagline">
      <div class="floating-box bg-default text-center">
        <h2 class="lead text-white p-5 text-center">
          Because Your Health Matters
        </h2>
      </div>
    </div>
  </div>
</div>