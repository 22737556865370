<footer class="footer">
  <div class="container">
    <div class="row row-grid align-items-center butter-cms">
      <div class="col-lg-6 powered-by">
        <h5 class="mb-0 font-weight-light">
          Site content powered by 
        </h5>
        <img src="assets/img/butter-cms.png" style="width: 300px;"/>
      </div>
    </div>
    <hr />
    <div class="row align-items-center justify-content-md-between">
      <div class="col-md-6">
        <div class="copyright">
          © {{ date | date: "yyyy" }}
          <a routerLink="/"> Fresh Health Network </a> .
        </div>
      </div>
      <div class="col-md-6">
      </div>
    </div>
  </div>
</footer>
